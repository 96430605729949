<template>
  <div class="test01">
    <SwitchButton class="switch"/>
    <div id="viewer"></div>
  </div>
</template>
<script>
// 文档参考 https://photo-sphere-viewer.js.org/api/
import { Viewer } from "photo-sphere-viewer";
import { CubemapAdapter } from "photo-sphere-viewer/dist/adapters/cubemap";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import SwitchButton from "./components/SwitchButton.vue";

import baseConfig from "../../config";

export default {
  components: {
    SwitchButton
  },
  data() {
    return {
      viewer: null,
    };
  },
  mounted() {
    var nsStr = "ns3";
    this.viewer = new Viewer({
      container: document.querySelector("#viewer"),
      panorama: {
        left: `${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/${nsStr}/l.jpg`,
        front: `${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/${nsStr}/f.jpg`,
        right: `${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/${nsStr}/r.jpg`,
        back: `${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/${nsStr}/b.jpg`,
        top: `${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/${nsStr}/u.jpg`,
        bottom: `${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/${nsStr}/d.jpg`,
      },
      adapter: CubemapAdapter,
      size: {
        width: "100vw",
        height: "100vh",
      },
      defaultZoomLvl: 20,
      navbar: false
    });

    this.viewer.adapter.config.flipTopBottom = true;

  },
};
</script>

<style scoped>

.test01 .switch {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
}
</style>