<template>
  <div
    draggable="false"
    class="test02"
    @mousedown="onMouseDown"
    @touchstart.prevent="onTouchStart"
    @mousemove="onMouseMove"
    @touchmove.prevent="onTouchMove"
    @mouseup="onMouseUp"
    @touchend.prevent="onTouchEnd"
  >
    <img :src="images[index % images.length]" draggable="false" alt="" />

    <!-- 预先获取 -->
    <div style="display: none;">
      <img :src="img" alt="" v-for="img in images" :key="img">
    </div>
    <SwitchButton class="switch"/>
  </div>
</template>

<script>
import SwitchButton from "./components/SwitchButton.vue";

import baseConfig from "../../config";

export default {
  components: {
    SwitchButton
  },
  data() {
    return {
      index: 36 * 999999 + 21,
      images: [],

      basicIndex: 0,
      currentX: null,
      operating: false,
    };
  },
  mounted() {
    var ext = this.$route.query.ext || "jpg";
    [...Array(36).keys()].forEach((i) => {
      this.images.push(`${baseConfig.proxy}/${this.$route.query.project}/${this.$route.query.car}/wg/pano_${i}.${ext}`);
    });

    this.current = this.images[0];
  },

  methods: {
    onTouchStart(e) {
      var touch = e.touches[0];
      this.basicIndex = this.index;
      this.currentX = touch.clientX - 20;
      this.operating = true;
    },
    onTouchMove(e) {
      if (!this.operating) return;
      var touch = e.touches[0];
      this.index =
        this.basicIndex + Math.floor((touch.clientX - this.currentX) / 40);
    },
    onTouchEnd() {
      this.currentX = null;
      this.operating = false;
    },

    onMouseDown(e) {
      this.basicIndex = this.index;
      this.currentX = e.x - 20;
      this.operating = true;
    },
    onMouseMove(e) {
      if (!this.operating) return;
      this.index = this.basicIndex + Math.floor((e.x - this.currentX) / 40);
    },
    onMouseUp() {
      this.currentX = null;
      this.operating = false;
    },
  },
};
</script>

<style scoped>
.test02 {
  user-select: none;
  width: 100%;
  height: 100%;
}
.test02 img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
}
.test02 .switch {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
}
</style>