<template>
  <div class="switchBtn">
    <button
      class="switchBtnItem"
      :class="$route.path.includes('wg') ? 'selected' : ''"
      @touchstart.stop="switchTo('/wg')"
      @click.capture.stop="switchTo('/wg')"
    >
      外观
    </button>
    <button
      v-for="(item, index) in nsList"
      :key="index"
      class="switchBtnItem"
      :class="$route.path.includes('ns' + item.key) ? 'selected' : ''"
      @touchstart.stop="switchTo('/ns' + item.key)"
      @click.capture.stop="switchTo('/ns' + item.key)"
    >
      内饰{{ item.key }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    nsList() {
      var len = this.$route.query.nslen;
      if (!len) {
        return [
          {
            key: "",
          },
        ];
      } else {
        len = Number(len);
        return [...Array(len).keys()].map(key => ({
          key: (key + 1).toString()
        }));
      }
    },
  },
  methods: {
    switchTo(path) {
      this.$router.replace({
        path,
        query: this.$route.query,
      });
    },
  },
};
</script>

<style scoped>
.switchBtn {
  width: auto;
  height: 34px;
  background: rgba(0, 0, 0, 0.63);
  border-radius: 17px;
  opacity: 1;
  padding: 0 5px;
  white-space: nowrap;
}
.switchBtnItem {
  width: 56px;
  height: 26px;
  border-radius: 13px 13px 13px 13px;
  color: #ffffff99;
  background-color: #ffffff00;
  border: 0;
  margin-top: 4px;
}
.selected {
  background: linear-gradient(137deg, #1c6fff 0%, #0ccde2 100%);
  opacity: 1;
  color: #ffffff;
}
</style>