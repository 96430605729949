
<template>
  <div v-show="dialogVisible" class="layer" @touchstart="dialogVisible=false" @click="dialogVisible=false">
    <div v-html="innerHtml" class="dialog" @click.stop @touchstart.stop></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      innerHtml: "",
      dialogVisible: false,
    };
  },
  methods: {
    show(info) {
      this.title = info.title;
      this.innerHtml = info.content;
      this.dialogVisible = true;
    },
    hide(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.layer{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
}
.dialog{
  position: absolute; left: 50%; top: 50%; width: 80%; transform: translate(-50%, -50%);
}
</style>
