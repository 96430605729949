<template>
  <div>
    <div class="switchBtn" style="margin-top: 5px;">
      <button
        v-if="!$route.query.wgnodefault"
        class="switchBtnItem"
        :class="$route.path == wgPath(0) ? 'selected' : ''"
        @touchstart.stop="switchTo(wgPath(0))"
        @click.capture.stop="switchTo(wgPath(0))"
      >
        默认角度
      </button>
      <button
        v-for="(item, index) in wgList"
        :key="index"
        class="switchBtnItem"
        :class="$route.path == wgPath(item.val) ? 'selected' : ''"
        @touchstart.stop="switchTo(wgPath(item.val))"
        @click.capture.stop="switchTo(wgPath(item.val))"
      >
        外观{{ item.key }}
      </button>
    </div>
    <div class="switchBtn" style="margin-top: 5px;">
      <button
        v-for="(item, index) in nsList"
        :key="index"
        class="switchBtnItem"
        :class="$route.path == nsPath(item.val) ? 'selected' : ''"
        @touchstart.stop="switchTo(nsPath(item.val))"
        @click.capture.stop="switchTo(nsPath(item.val))"
      >
        内饰{{ item.key }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    var otherwg = (this.$route.query.otherwg || "").split(",").filter(x => x);
    var otherns = (this.$route.query.otherns || "").split(",").filter(x => x);
    return {
      otherwg, otherns
    }
  },
  computed: {
    wgList(){
      var len = this.otherwg.length;
      if(len){
        len = Number(len);
        return [...Array(len).keys()].map(index => ({
          key: this.otherwg.length > 1 ? (index + 1).toString() : '',
          val: this.otherwg[index]
        }));
      }
      return [];
    },
    nsList() {
      var len = this.otherns.length;
      if (!len) {
        return [
          {
            key: "",
            val: "ns"
          },
        ];
      } else {
        len = Number(len);
        return [...Array(len).keys()].map(index => ({
          key: (index + 1).toString(),
          val: this.otherns[index]
        }));
      }
    },

  },
  methods: {
    wgPath(i){
      return `/v2/wg/${this.$route.params.project}/${this.$route.params.car}/${i}`
    },
    nsPath(nsPoint){
      return `/v2/ns/${this.$route.params.project}/${this.$route.params.car}/${nsPoint}`
    },
    switchTo(path) {
      this.$router.replace({
        path,
        query: this.$route.query,
      }).catch(err => err);
    },
  },
};
</script>

<style scoped>
.switchBtn {
  width: auto;
  height: 34px;
  background: rgba(0, 0, 0, 0.63);
  border-radius: 17px;
  opacity: 1;
  padding: 0 5px;
  white-space: nowrap;
  display: inline-block;
}
.switchBtnItem {
  width: 65px;
  height: 26px;
  border-radius: 13px 13px 13px 13px;
  color: #ffffff99;
  background-color: #ffffff00;
  border: 0;
  margin-top: 4px;
  outline: none!important;;
}
.selected {
  background: linear-gradient(137deg, #1c6fff 0%, #0ccde2 100%);
  opacity: 1;
  color: #ffffff;
}
</style>