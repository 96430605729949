<template>
  <div>
    <div class="switchBtn" style="margin-top: 5px;">
      <button
        class="switchBtnItem"
        :class="$route.path == '/V0712wg' ? 'selected' : ''"
        @touchstart.stop="switchTo('/V0712wg')"
        @click.capture.stop="switchTo('/V0712wg')"
      >
        默认角度
      </button>
      <button
        v-for="(item, index) in wgList"
        :key="index"
        class="switchBtnItem"
        :class="$route.path.includes('V0712wg' + item.key) ? 'selected' : ''"
        @touchstart.stop="switchTo('/V0712wg' + item.key)"
        @click.capture.stop="switchTo('/V0712wg' + item.key)"
      >
        外观{{ item.key }}
      </button>
    </div>
    <div class="switchBtn" style="margin-top: 5px;">
      <button
        v-for="(item, index) in nsList"
        :key="index"
        class="switchBtnItem"
        :class="$route.path == '/V0712ns' + item.key ? 'selected' : ''"
        @touchstart.stop="switchTo('/V0712ns' + item.key)"
        @click.capture.stop="switchTo('/V0712ns' + item.key)"
      >
        内饰{{ item.key }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    wgList(){
      var len = this.$route.query.wglen;
      if(len){
        len = Number(len);
        return [...Array(len).keys()].map(key => ({
          key: (key + 1).toString()
        }));
      }
      return [];
    },
    nsList() {
      var len = this.$route.query.nslen;
      if (!len) {
        return [
          {
            key: "",
          },
        ];
      } else {
        len = Number(len);
        return [...Array(len).keys()].map(key => ({
          key: (key + 1).toString()
        }));
      }
    },
  },
  methods: {
    switchTo(path) {
      this.$router.replace({
        path,
        query: this.$route.query,
      });
    },
  },
};
</script>

<style scoped>
.switchBtn {
  width: auto;
  height: 34px;
  background: rgba(0, 0, 0, 0.63);
  border-radius: 17px;
  opacity: 1;
  padding: 0 5px;
  white-space: nowrap;
  display: inline-block;
}
.switchBtnItem {
  width: 80px;
  height: 26px;
  border-radius: 13px 13px 13px 13px;
  color: #ffffff99;
  background-color: #ffffff00;
  border: 0;
  margin-top: 4px;
  outline: none!important;;
}
.selected {
  background: linear-gradient(137deg, #1c6fff 0%, #0ccde2 100%);
  opacity: 1;
  color: #ffffff;
}
</style>