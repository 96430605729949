<template>
  <div>
    <div v-if="wgList.length" class="switchBtn" style="margin-top: 5px;">
      <button
        v-for="(item, index) in wgList"
        :key="index"
        class="switchBtnItem"
        :class="$route.params.index == item.index ? 'selected' : ''"
        @touchstart.stop="switchTo(wgPath(item.index))"
        @click.capture.stop="switchTo(wgPath(item.index))"
      >
        {{ item.label }}
      </button>
    </div>
    <div v-if="nsList.length" class="switchBtn" style="margin-top: 5px;">
      <button
        v-for="(item, index) in nsList"
        :key="index"
        class="switchBtnItem"
        :class="$route.params.index == item.index ? 'selected' : ''"
        @touchstart.stop="switchTo(nsPath(item.index), item.defaultLong)"
        @click.capture.stop="switchTo(nsPath(item.index), item.defaultLong)"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>

import baseConfig from "../../../config";

export default {
  data(){
    return {
      config: null,
      wgList: [],
      nsList: [],
    }
  },
  async mounted(){
    
    var project = this.$route.params.project;
    var car = this.$route.params.car;
    var index = this.$route.params.index;
    
    try{
      this.config = await fetch(`${baseConfig.proxy}/${project}/${car}/config.json`).then(res => res.text()).then(res => JSON.parse(res));
      this.wgList = this.config.wg.position;
      this.nsList = this.config.ns.position;
    }
    catch(e){
      console.log(e)
    }
  },
  methods: {
    wgPath(i){
      return `/v3/wg/${this.$route.params.project}/${this.$route.params.car}/${i}`
    },
    nsPath(nsPoint){
      return `/v3/ns/${this.$route.params.project}/${this.$route.params.car}/${nsPoint}`
    },
    switchTo(path, defaultLong = 0) {
      this.$router.replace({
        path,
        query: {
          ...this.$route.query,
          defaultLong
        },
      }).catch(err => err);
    },
  },
};
</script>

<style scoped>
.switchBtn {
  width: auto;
  height: 34px;
  background: rgba(0, 0, 0, 0.63);
  border-radius: 17px;
  opacity: 1;
  padding: 0 5px;
  white-space: nowrap;
  display: inline-block;
}
.switchBtnItem {
  width: 65px;
  height: 26px;
  border-radius: 13px 13px 13px 13px;
  color: #ffffff99;
  background-color: #ffffff00;
  border: 0;
  margin-top: 4px;
  outline: none!important;;
}
.selected {
  background: linear-gradient(137deg, #1c6fff 0%, #0ccde2 100%);
  opacity: 1;
  color: #ffffff;
}
@media screen and (max-width:700px) {
  
  .switchBtn {
    width: auto;
    height: 26px;
    background: rgba(0, 0, 0, 0.63);
    border-radius: 17px;
    opacity: 1;
    padding: 0 2px;
    white-space: nowrap;
    display: inline-block;
  }
  .switchBtn button{
    font-size: 12px!important;
  }
  .switchBtnItem {
    width: auto;
    height: 20px;
    border-radius: 13px 13px 13px 13px;
    color: #ffffff99;
    background-color: #ffffff00;
    border: 0;
    margin-top: 3px;
    outline: none!important;
    padding: 0 5px;
  }
  .selected {
    background: linear-gradient(137deg, #1c6fff 0%, #0ccde2 100%);
    opacity: 1;
    color: #ffffff;
  }
}

@media screen and (max-width:300px) {
  .switchBtn {
    display: none;
  }
}
</style>