import Vue from "vue";
import Router from "vue-router";

import index from './views/index.vue'
import home from './views/home.vue'
import ns from './views/default/ns.vue'
import wg from './views/default/wg.vue'

import V0710ns1 from './views/V0710/ns1.vue'
import V0710ns2 from './views/V0710/ns2.vue'
import V0710ns3 from './views/V0710/ns3.vue'
import V0710wg from './views/V0710/wg.vue'
import V0710wg1 from './views/V0710/wg1.vue'
import V0710wg2 from './views/V0710/wg2.vue'
import V0710wg3 from './views/V0710/wg3.vue'


import V0712ns1 from './views/V0712/ns1.vue'
import V0712ns2 from './views/V0712/ns2.vue'
import V0712ns3 from './views/V0712/ns3.vue'
import V0712wg from './views/V0712/wg.vue'
import V0712wg1 from './views/V0712/wg1.vue'
import V0712wg2 from './views/V0712/wg2.vue'
import V0712wg3 from './views/V0712/wg3.vue'



import v2ns from './views/v2/ns.vue'
import v2wg from './views/v2/wg.vue'


import v3ns from './views/v3/ns.vue'
import v3wg from './views/v3/wg.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/home",
      component: home,
    },
    {
      path: "/v2/wg/:project/:car/:wgindex",
      component: v2wg
    },
    {
      path: "/v2/ns/:project/:car/:nsindex",
      component: v2ns
    },
    {
      path: "/v3/wg/:project/:car/:index",
      component: v3wg
    },
    {
      path: "/v3/ns/:project/:car/:index",
      component: v3ns
    },
    {
      path: "/ns",
      component: ns,
    },
    {
      path: "/wg",
      component: wg,
    },
    {
      path: "/V0710wg",
      component: V0710wg,
    },
    {
      path: "/V0710wg1",
      component: V0710wg1,
    },
    {
      path: "/V0710wg2",
      component: V0710wg2,
    },
    {
      path: "/V0710wg3",
      component: V0710wg3,
    },
    {
      path: "/V0710ns1",
      component: V0710ns1,
    },
    {
      path: "/V0710ns2",
      component: V0710ns2,
    },
    {
      path: "/V0710ns3",
      component: V0710ns3,
    },

    
    {
      path: "/V0712wg",
      component: V0712wg,
    },
    {
      path: "/V0712wg1",
      component: V0712wg1,
    },
    {
      path: "/V0712wg2",
      component: V0712wg2,
    },
    {
      path: "/V0712wg3",
      component: V0712wg3,
    },
    {
      path: "/V0712ns1",
      component: V0712ns1,
    },
    {
      path: "/V0712ns2",
      component: V0712ns2,
    },
    {
      path: "/V0712ns3",
      component: V0712ns3,
    },
  ]
})