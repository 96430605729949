<template>
  <div class="test01">
    <SwitchButton class="switch" />
    <div id="viewer" :key="key"></div>
    <TagViewer ref="tagViewer"/>
  </div>
</template>
<script>
// 文档参考 https://photo-sphere-viewer.js.org/api/
import { Viewer } from "photo-sphere-viewer";
import { CubemapAdapter } from "photo-sphere-viewer/dist/adapters/cubemap";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import SwitchButton from "./components/SwitchButton.vue";

import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers'
import 'photo-sphere-viewer/dist/plugins/markers.css'

import TagViewer from "./components/TagViewer.vue"

import baseConfig from "../../config";


export default {
  components: {
    SwitchButton, TagViewer
  },
  data() {
    return {
      viewer: null,
      key: 0,
      markers: [],
    };
  },
  watch: {
    "$route.path"() {
      this.key = new Date().getTime();
      this.$nextTick(() => {
        this.init();
      })
    },
  },
  methods: {
    async init() {
      
      var project = this.$route.params.project;
      var car = this.$route.params.car;
      var nsPoint = this.$route.params.nsindex;
      
      // 只有第一个点位 面向前面
      var otherns = (this.$route.query.otherns || "").split(",").filter(x => x);
      var defaultLong = otherns.indexOf(nsPoint) > 0 ? Math.PI : 0;

      try{
        var markers_config = await fetch(`${baseConfig.proxy}/${project}/${car}/markers.json`).then(res => res.text()).then(res => JSON.parse(res));
        this.markers = markers_config[nsPoint] || [];
      }
      catch(e){

      }

      this.viewer = new Viewer({
        container: document.querySelector("#viewer"),
        panorama: {
          left: `${baseConfig.proxy}/${project}/${car}/${nsPoint}/l.jpg`,
          front: `${baseConfig.proxy}/${project}/${car}/${nsPoint}/f.jpg`,
          right: `${baseConfig.proxy}/${project}/${car}/${nsPoint}/r.jpg`,
          back: `${baseConfig.proxy}/${project}/${car}/${nsPoint}/b.jpg`,
          top: `${baseConfig.proxy}/${project}/${car}/${nsPoint}/u.jpg`,
          bottom: `${baseConfig.proxy}/${project}/${car}/${nsPoint}/d.jpg`,
        },
        adapter: CubemapAdapter,
        size: {
          width: "100vw",
          height: "100vh",
        },
        defaultZoomLvl: 20,
        defaultLong,
        navbar: false,

        plugins: [
          [MarkersPlugin, {
              markers: this.markers,
          }],
        ],
      });

      const markersPlugin = this.viewer.getPlugin(MarkersPlugin);

      markersPlugin.on('select-marker', (e, marker) => {
        this.$refs.tagViewer.show(marker.config.extInfo);
      });

      
      if(this.$route.query.nsflip){
        this.viewer.adapter.config.flipTopBottom = true;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.test01 .switch {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 100%;
}
</style>

<style>
.custom-tooltip{
  width: 500px;
}
</style>