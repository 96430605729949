<template>
  <div class="test02">
    <div
      :key="key"
      draggable="false"
      class="test02"
      @mousedown="onMouseDown"
      @touchstart.prevent="onTouchStart"
      @mousemove="onMouseMove"
      @touchmove.prevent="onTouchMove"
      @mouseup="onMouseUp"
      @touchend.prevent="onTouchEnd"
    >
      <div class="view">
        <img :src="images[index % images.length]" draggable="false" alt="" />
        <div>
          <div v-for="(marker, i) in markers" :key="i" v-show="markers.some(x => x.index == index % images.length)" 
            class="marker" @touchend.stop="showMarker(marker)" @click="showMarker(marker)" :style="marker.style"></div>
        </div>
      </div>
      <!-- 预先获取 -->
      <div style="display: none;">
        <img :src="img" alt="" v-for="img in images" :key="img">
      </div>
      <SwitchButton class="switch"/>
      <TagViewer ref="tagViewer"/>
    </div>
  </div>
</template>

<script>
import SwitchButton from "./components/SwitchButton.vue";
import TagViewer from "./components/TagViewer.vue"

import baseConfig from "../../config";

export default {
  components: {
    SwitchButton, TagViewer
  },
  data() {
    var total = this.$route.query.wgtotal || 36;
    total = Number(total)
    return {

      key: 0,

      total,
      index: total * 99999,
      images: [],

      basicIndex: 0,
      currentX: null,
      operating: false,

      markers: [],
    };
  },
  watch: {
    "$route.path"() {
      this.key = new Date().getTime();
      this.$nextTick(() => {
        this.init();
      })
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init(){
      
      var project = this.$route.params.project;
      var car = this.$route.params.car;

      this.images = [];
      var wgindex = this.$route.params.index || 0;
      var ext = this.$route.query.wgext || "jpg";

      try{
        var config = await fetch(`${baseConfig.proxy}/${project}/${car}/config.json`).then(res => res.text()).then(res => JSON.parse(res));
        ext = config.wg.ext || ext;
      }
      catch(e){
        console.log(e)
      }

      [...Array(this.total).keys()].forEach((i) => {
        this.images.push(`${baseConfig.proxy}/${project}/${car}/wg/pano_${i}.${ext}`);
      });
      this.index = this.total * 999999 + Number(wgindex);
    },
    onTouchStart(e) {
      if(this.$route.query.wgnodrag){
        return;
      }
      var touch = e.touches[0];
      this.basicIndex = this.index;
      this.currentX = touch.clientX - 20;
      this.operating = true;
    },
    onTouchMove(e) {
      if (!this.operating) return;
      var touch = e.touches[0];
      this.index =
        this.basicIndex + Math.floor((touch.clientX - this.currentX) / 40);
    },
    onTouchEnd() {
      this.currentX = null;
      this.operating = false;
    },

    onMouseDown(e) {
      if(this.$route.query.wgnodrag){
        return;
      }
      this.basicIndex = this.index;
      this.currentX = e.x - 20;
      this.operating = true;
    },
    onMouseMove(e) {
      if (!this.operating) return;
      this.index = this.basicIndex + Math.floor((e.x - this.currentX) / 40);
    },
    onMouseUp() {
      this.currentX = null;
      this.operating = false;
    },

    showMarker(marker){
      this.$refs.tagViewer.show(marker.extInfo);
    }
  },
};
</script>

<style scoped>
.test02 {
  user-select: none;
  width: 100%;
  height: 100%;
}
.test02 .view {
  width: 100%;
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
}
.test02 .view img{
  width: 100%;
}
.test02 .switch {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 100%;
}
@media screen and (max-width:700px) {
  .test02 .switch {
    bottom: 5px;
  }
}
.marker{
  position: absolute; left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 26px;
  border-radius: 15px;
  border: solid 2px yellow;
  background-color: rgba(255,255,0,0.3);
}
</style>