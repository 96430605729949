<template>
  <div class="home">

    <h3>0615版本-C100车辆</h3>
    <hr>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C100&car=ad-a4l')">奥迪-A4L</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C100&car=bk-jw')">别克-君威</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C100&car=dz-pst')">大众-帕萨特</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C100&car=ft-kmr')">丰田-凯美瑞</a>

    <h3>0615版本-C101车辆</h3>
    <hr>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C101&car=ad-a5')">奥迪-A5</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C101&car=dz-mt')">大众-迈腾</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C101&car=dz-cc')">大众-CC</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C101&car=bt-yg')">本田-雅阁</a>

    <h3>0615版本-C095车辆</h3>
    <hr>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C095&car=bc-v')">奔驰-V</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C095&car=bk-gl8')">别克GL8</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C095&car=bt-als')">本田-艾力绅</a>
    <a href="javascript: void(0);" @click="$router.push('/wg?project=C095&car=ft-aef')">丰田-阿尔法</a>

    <h3>0601版本-C100车辆</h3>
    <hr>
    <a href="javascript: void(0);" @click="$router.push('/wg??project=C100-old&car=ad-a4l')">奥迪-A4L</a>
    <a href="javascript: void(0);" @click="$router.push('/wg??project=C100-old&car=bt-yg')">本田-雅阁</a>
    <a href="javascript: void(0);" @click="$router.push('/wg??project=C100-old&car=dz-mt')">大众-迈腾</a>
    <a href="javascript: void(0);" @click="$router.push('/wg??project=C100-old&car=ft-kmr')">丰田-凯美瑞</a>
    <a href="javascript: void(0);" @click="$router.push('/wg??project=C100-old&car=ft-yzl')">丰田-亚洲龙</a>
    
    <h3>0826版本-C100车辆</h3>
    <hr>
    <a href="javascript: void(0);" @click="$router.push('/v3/wg/C100-0826/hqh5-2023/4')">红旗H5-2023</a>
    <a href="javascript: void(0);" @click="$router.push('/v3/wg/C100-0826/ad-a4l/3?&nsflip=1')">奥迪-A4L</a>
    <a href="javascript: void(0);" @click="$router.push('/v3/wg/C100-0826/dz-mt/3?&nsflip=1')">大众-迈腾</a>
    <a href="javascript: void(0);" @click="$router.push('/v3/wg/C100-0826/ft-kmr/3?&nsflip=1')">丰田-凯美瑞</a>
    <a href="javascript: void(0);" @click="$router.push('/v3/wg/C100-0826/bt-yg/3?&nsflip=1')">本田-雅阁</a>
    <a href="javascript: void(0);" @click="$router.push('/v3/wg/C100-0826/byd-h-dmi/3?nsflip=1')">比亚迪-汉</a>

    <h3>测试</h3>
    <hr>
    <!-- <a href="javascript: void(0);" @click="$router.push('/V0710wg?project=0710&car=lt-mxj&ext=png&wglen=3&nslen=3')">蓝图-梦想家</a> -->
    <a href="javascript: void(0);" @click="$router.push('/V0712wg?project=0712&car=lt-mxj&ext=png&wglen=3&nslen=3')">蓝图-梦想家</a>
    <a href="javascript: void(0);" @click="$router.push('/v2/wg/C095/095/0?wgext=png&otherns=ns1,ns2&otherwg=0,1,2,3,4&wgnodrag=1&wgnodefault=1&wgtotal=5')">095</a>
    <a href="javascript: void(0);" @click="$router.push('/v4/wg/C100-0826/hqh5-2023/4')">红旗H5-2023</a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.home{
  padding-top: 100px;
}
.home a{
  margin: 0 10px;
}
</style>